import { ReactElement, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { PAGE_URL } from '../../../constants';
import { Meeting, Meetings } from '../../../store';
import { sortMeetingLogs } from '../../../utils/scheduleUtils';
import colors from '../../../colors';
import MeetingListItem from '../../Dashboard/MeetingListItem';
import UpdatedDashboardWidget from '../../Dashboard/UpdatedDashboardWidget';


interface Props {
  meetings: Meetings;
  meetingsLoaded: boolean;
  onUpdateStatus: (id: number, status: number) => Promise<void>;
}

interface MeetingProps {
  meeting: Meeting;
}

export const LeaderMeetingsWidget = ({
  meetings, meetingsLoaded, onUpdateStatus
}: Props): ReactElement => {
  const sortedMeetings = useMemo(() => {
    return Object.keys(meetings).sort(sortMeetingLogs(meetings));
  }, [meetings]);


  const MeetingWidgetItem = ({meeting}: MeetingProps): ReactElement => {
    if (meeting?.leaders) { // shouldn't really be necessary but we've encountered errors when navigating quickly
      const respondents = Object.values(meeting.participants || {}).filter(p => p.first_response_date).length;
      let percentRes = 0;
      if (meeting.num_expected_participants !== undefined) {
        percentRes = (respondents / meeting.num_expected_participants) * 100;
      }

      return (
        <MeetingListItem
          meeting={meeting}
          key={meeting.id}
          onUpdateStatus={onUpdateStatus}
          navigateTo={`${PAGE_URL.SCHEDULE}/${meeting.id}/`}
          titleSx={{fontWeight: 500, marginBottom: '4px'}}
          containerSx={{paddingLeft: 0, marginBottom: '1px'}}
          subtitle={
            <Box>
              {meeting.is_poll ? (
                <Box display='flex' gap={.25} alignItems='center'>
                  <Typography variant='body2' fontSize={12} color={colors.black700}>
                    <strong>Poll:</strong>
                  </Typography>
                  <Typography variant='body2' fontSize='12px' 
                    color={percentRes < 33 ? colors.redError : percentRes > 65 ? colors.greenPrimary 
                      : colors.coralPrimary}>
                    {`${respondents}/${meeting.num_expected_participants} responses`}
                  </Typography>
                </Box>
              ) : (
                <Typography variant='body2' fontSize={12} color={colors.black700}>
                  <strong>One-off:</strong> waiting for response
                </Typography>
              )}
            </Box>
          }
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <UpdatedDashboardWidget
      title={'One-off Meetings & Polls'}
      isLoading={!meetingsLoaded}
      isEmpty={sortedMeetings.length === 0 && meetingsLoaded}
      emptyMessage={'This team member has no pending Meetings or Polls'}
      borderColorOverride={colors.black200}
      allLoaded={meetingsLoaded}
      children={
        <Box>
          {
            sortedMeetings && (
              sortedMeetings.map((meetingId) => 
                <MeetingWidgetItem key={meetingId} meeting={meetings[meetingId]}/>)
            )
          }
        </Box>
      }
    />
  );
};
  
export default LeaderMeetingsWidget;